$body-background-color: $white !default;
$body-size: 16px !default;
$body-rendering: optimizeLegibility !default;
$body-family: $family-primary !default;
$body-color: $text !default;
$body-weight: $weight-normal !default;
$body-line-height: 1.5 !default;

$code-family: $family-code !default;
$code-padding: 0.25em 0.5em 0.25em !default;
$code-weight: normal !default;
$code-size: 0.875em !default;

$hr-background-color: $background !default;
$hr-height: 2px !default;
$hr-margin: 1.5rem 0 !default;

$strong-color: $text-strong !default;
$strong-weight: $weight-bold !default;

html {
  background-color: $body-background-color;
  font-size: $body-size;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: $body-rendering;
  text-size-adjust: 100%; }

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block; }

body,
button,
input,
select,
textarea {
  font-family: $body-family; }

code,
pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: $code-family; }

body {
  color: $body-color;
  font-size: 1rem;
  font-weight: $body-weight;
  line-height: $body-line-height; }

// Inline

a {
  color: $link;
  cursor: pointer;
  text-decoration: none;
  strong {
    color: currentColor; }
  &:hover {
    color: $link-hover; } }

code {
  background-color: $code-background;
  color: $code;
  font-size: $code-size;
  font-weight: $code-weight;
  padding: $code-padding; }

hr {
  background-color: $hr-background-color;
  border: none;
  display: block;
  height: $hr-height;
  margin: $hr-margin; }

img {
  height: auto;
  max-width: 100%; }

input[type="checkbox"],
input[type="radio"] {
  vertical-align: baseline; }

small {
  font-size: 0.875em; }

span {
  font-style: inherit;
  font-weight: inherit; }

strong {
  color: $strong-color;
  font-weight: $strong-weight; }

// Block

pre {
  @include overflow-touch;
  background-color: $pre-background;
  color: $pre;
  font-size: 0.875em;
  overflow-x: auto;
  padding: 1.25rem 1.5rem;
  white-space: pre;
  word-wrap: normal;
  code {
    background-color: transparent;
    color: currentColor;
    font-size: 1em;
    padding: 0; } }

table {
  td,
  th {
    text-align: left;
    vertical-align: top; }
  th {
    color: $text-strong; } }
