$table-color: $grey-darker !default;
$table-background-color: $white !default;

$table-cell-border: 1px solid $grey-lighter !default;
$table-cell-border-width: 0 0 1px !default;
$table-cell-padding: 0.5em 0.75em !default;
$table-cell-heading-color: $text-strong !default;

$table-head-cell-border-width: 0 0 2px !default;
$table-head-cell-color: $text-strong !default;
$table-foot-cell-border-width: 2px 0 0 !default;
$table-foot-cell-color: $text-strong !default;

$table-row-hover-background-color: $white-bis !default;

$table-row-active-background-color: $primary !default;
$table-row-active-color: $primary-invert !default;

$table-striped-row-even-background-color: $white-bis !default;
$table-striped-row-even-hover-background-color: $white-ter !default;

.table {
  @extend %block;
  background-color: $table-background-color;
  color: $table-color;
  td,
  th {
    border: $table-cell-border;
    border-width: $table-cell-border-width;
    padding: $table-cell-padding;
    vertical-align: top;
    // Colors
    @each $name, $pair in $colors {
      $color: nth($pair, 1);
      $color-invert: nth($pair, 2);
      &.is-#{$name} {
        background-color: $color;
        border-color: $color;
        color: $color-invert; } }
    // Modifiers
    &.is-narrow {
      white-space: nowrap;
      width: 1%; }
    &.is-selected {
      background-color: $table-row-active-background-color;
      color: $table-row-active-color;
      a,
      strong {
        color: currentColor; } } }
  th {
    color: $table-cell-heading-color;
    text-align: left; }
  tr {
    &.is-selected {
      background-color: $table-row-active-background-color;
      color: $table-row-active-color;
      a,
      strong {
        color: currentColor; }
      td,
      th {
        border-color: $table-row-active-color;
        color: currentColor; } } }
  thead {
    td,
    th {
      border-width: $table-head-cell-border-width;
      color: $table-head-cell-color; } }
  tfoot {
    td,
    th {
      border-width: $table-foot-cell-border-width;
      color: $table-foot-cell-color; } }
  tbody {
    tr {
      &:last-child {
        td,
        th {
          border-bottom-width: 0; } } } }
  // Modifiers
  &.is-bordered {
    td,
    th {
      border-width: 1px; }
    tr {
      &:last-child {
        td,
        th {
          border-bottom-width: 1px; } } } }
  &.is-fullwidth {
    width: 100%; }
  &.is-hoverable {
    tbody {
      tr:not(.is-selected) {
        &:hover {
          background-color: $table-row-hover-background-color; } } }
    &.is-striped {
      tbody {
        tr:not(.is-selected) {
          &:hover {
            background-color: $table-row-hover-background-color;
            &:nth-child(even) {
              background-color: $table-striped-row-even-hover-background-color; } } } } } }
  &.is-narrow {
    td,
    th {
      padding: 0.25em 0.5em; } }
  &.is-striped {
    tbody {
      tr:not(.is-selected) {
        &:nth-child(even) {
          background-color: $table-striped-row-even-background-color; } } } } }

.table-container {
  @extend %block;
  @include overflow-touch;
  overflow: auto;
  overflow-y: hidden;
  max-width: 100%; }
