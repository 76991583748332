.container {
  margin: 0 auto;
  position: relative;
  @include desktop {
    max-width: $desktop - (2 * $gap);
    width: $desktop - (2 * $gap);
    &.is-fluid {
      margin-left: $gap;
      margin-right: $gap;
      max-width: none;
      width: auto; } }
  @include until-widescreen {
    &.is-widescreen {
      max-width: $widescreen - (2 * $gap);
      width: auto; } }
  @include until-fullhd {
    &.is-fullhd {
      max-width: $fullhd - (2 * $gap);
      width: auto; } }
  @include widescreen {
    max-width: $widescreen - (2 * $gap);
    width: $widescreen - (2 * $gap); }
  @include fullhd {
    max-width: $fullhd - (2 * $gap);
    width: $fullhd - (2 * $gap); } }
